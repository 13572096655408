@import '../variables/colors.css';
@import '../variables/dimens.css';

.services-list {
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--padding);
  list-style: none;
  text-align: center;
}

.service-icon:hover {
  cursor: pointer;
}

.service-icon:before {
  content: '';
  width: 100px;
  height: 100px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--button-bg);
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: calc(var(--padding) / 2);
}

.service-icon:before:hover {
  background-color: var(--button-bg-hover);
}

.service-icon.extensions:before {
  background-image: url('/images/services/extensions.png');
}

.service-icon.renovations:before {
  background-image: url('/images/services/house-renovations.png');

}

.service-icon.driveways:before {
  background-image: url('/images/services/driveways.png');

}

.service-icon.fencing:before {
  background-image: url('/images/services/fencing.png');
}

.service-icon.loft-conversions:before {
  background-image: url('/images/services/loft-conversions.png');
}

.service-icon.patios:before {
  background-image: url('/images/services/patios.png');
}

.service-icon.landscaping:before {
  background-image: url('/images/services/landscaping.png');
}

.service-icon.bathrooms:before {
  background-image: url('/images/services/bathrooms.png');
}

@media (min-width: 1000px) {
  .services-list {
    margin: calc(var(--padding) * 2) 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
