@import '../variables/colors.css';
@import '../variables/dimens.css';

.album ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  list-style: none;
  margin: 0;
  padding: 0;
}

.album ul li {
  width: var(--gallery-img-size);
  height: var(--gallery-img-size);

  margin: 4px;
  border-width: 4px;
  border-style: solid;
  border-color:var(--second-brand-grey);
}

.album ul li:hover {
  border-color:var(--brand-green);
}

.album ul li img {
  width: var(--gallery-img-size);
  height: var(--gallery-img-size);

  object-fit: cover;
}

.gallery__view-more {
  display: none;
}

.gallery__view-more:nth-child(8) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  font-size: 1.2em;

  border-color: transparent;
  background-color: var(--second-brand-grey);
  color: var(--page-bg);
}

.gallery__view-more a {
  text-decoration: none;
}

@media (max-width: 384px) {
  .album ul {
    justify-content: center;
  }
}
