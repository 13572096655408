@import './components/carousel.css';
@import './components/nav.css';
@import './components/footer.css';
@import './components/gallery.css';
@import './components/services-list.css';
@import './variables/colors.css';
@import './variables/dimens.css';

html, body {
  padding: 0;
  margin: 0;

  font-family: sans-serif;
}

body {
  background-color: var(--page-bg);
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 2.4rem;
  text-transform: uppercase;
}

h2:after {
  content: '';
  display: block;
  width: 55%;
  height: 3px;
  background-color: var(--brand-green);
  margin-top: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

.inner-content {
  max-width: 840px;
  padding: 0 16px;
  margin: 0 auto;
}

main > section {
  padding-top: 32px;
  padding-bottom: 32px;
}

#projects h2 {
  margin-bottom: 0;
}

.u-flex-row {
  display: flex;
  flex-direction: column;
}

#contact {
  background-color: var(--light-grey);
}

#contact a {
  text-decoration: none;
}

.contact__form-container p {
  margin-top: 0;
}

.contact__img {
  display: none;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form textarea {
  height: 120px;
}

input, textarea {
  margin: 8px 0;
  padding: 16px;
  font-family: inherit;
}

input[type='submit'], button, .button {
  display:inline-block;

  background-color: var(--button-bg);
  color: var(--button-text-color);
  text-decoration: none;
  border: none;
  transition: background-color 200ms ease-in-out;
  margin-bottom: 0;
  padding: 16px;
  text-transform: uppercase;
}

input[type='submit']:hover, button:hover, .button:hover {
  background-color: var(--button-bg-hover);
}

.services__img {
  display: block;
  transition: opacity .6s ease-in-out;

  width: 100%;
  height: 300px;

  margin-top: var(--padding);
  background-position-x: center;
  background-position-y: bottom;
  background-size: cover;
}

@media (max-width: 260px) {
  body {
    font-size: 14px;
  }

  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
  }
}

@media (min-width: 760px) {
  .contact__form-container {
    max-width: 360px;
  }

  .u-flex-row {
    flex-direction: row;
  }

  .services__img {
    margin-left: 32px;
  }

  .contact__img {
    margin-left: 32px;
    background-image: url('/images/contact-image.jpg');
  }

  .contact__img, .services__img {
    display: block;
    flex: 1;
    width: auto;
    height: auto;
    margin-top: 0;
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
  }
}
