@import '../variables/colors.css';
@import '../variables/dimens.css';

nav {
  width: 100%;
  height: var(--nav-mobile-height);
  background-color: var(--page-bg);
  position: relative;
}

.nav-menu-icon svg {
  width: var(--nav-menu-height);
  height: var(--nav-menu-height);
  padding: calc((var(--nav-mobile-height) - var(--nav-menu-height)) / 2);
  fill: #242120;
}

.nav-logo {
  width: 100%;
  height: var(--nav-mobile-height);

  position: absolute;
  left: 0;
  top: 0;

  text-align: center;
}

.nav-logo svg {
  height: calc(var(--nav-mobile-height) - 8px);
  margin: 4px 0;
}

.nav-content {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  transform: translateX(-100%);
  transition: transform 400ms ease-in;

  background-color: var(--light-grey);

  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-content.show {
  transform: translateX(0);
}

.nav-content li a, .nav-content li .nav-content__title {
  display: block;
  padding: var(--nav-drawer-vertical-padding) var(--nav-drawer-horizontal-padding);
  text-decoration: none;
}

.nav-content li:after {
  content: '';
  display: block;
  height: 1px;
  background-color: var(--secondary-grey);
  margin: 0 var(--nav-drawer-horizontal-padding);
}

.nav-content__title {
  text-transform: uppercase;
  font-size: 1.4rem;
}

@media (min-width: 1000px) {
  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: auto;
  }

  .nav-menu-icon {
    display: none;
  }

  .nav-logo {
    width: unset;
    position: relative;
    padding: 0 var(--nav-drawer-horizontal-padding);
  }

  .nav-content {
    position: relative;

    display: flex;
    flex-direction: row;

    width: unset;

    transform: none;
    transition: none;

    background: none;
  }

  .nav-content li:after {
    content: none;
  }

  .nav-content .nav-content__title {
    display: none;
  }
}
