@import '../variables/colors.css';

.carousel {
  width: 100%;
  height: 400px;
  max-height: 80vh;
  background-color: var(--secondary-grey);
  background-position: center;
  background-size: cover;
}

@media (min-width: 1000px) {
  .carousel {
    height: 500px;
    max-height: unset;
  }
}
